import Highcharts from 'highcharts/highcharts';
import BoostModule from 'highcharts/modules/boost';
import NoDataModule from 'highcharts/modules/no-data-to-display';
import StockModule from 'highcharts/modules/stock';

const lineColor = 'var(--v-outline-variant)';
const textColor = 'var(--v-on-surface)';

export function getTooltipHeader(title = '{point.key}', additionalData = '') {
  return `
    <div class="grid grid-x2 gap-2 pa-2">
      <div class="text-left text-subtitle-3 align-baseline">${title}</div>
      <div class="d-flex justify-end align-self-center">${additionalData}</div>
  `;
}

export function getTooltipPointFormat(
  serieName = '{series.name}',
  value = '{point.y}',
  color: Highcharts.TooltipFormatterContextObject['color'] = '{point.color}'
) {
  return `
    <div class="text-left d-flex align-center">
      <div class="v-avatar mr-1" style="height: 12px; min-width: 12px; width: 12px; background: ${color};"></div>
      <span>${serieName}</span>
    </div>
    <div class="pl-4 text-right">${value}</div>
  `;
}
export function getTooltipFooter() {
  return '</div>';
}

export const colors = [
  '#D1681C',
  '#EEB70B',
  '#8AF0A5',
  '#08BDBA',
  '#047D79',
  '#BAE6FF',
  '#53BDFF',
  '#5392FF',
  '#9F63FA',
  '#D4BBFF',
  '#E82C7E',
  '#FF85BA',
];

Highcharts.setOptions({
  // Based on https://www.figma.com/file/an3DAamecy5V0GfKSfn4at/Console-Core?node-id=58561%3A115084&mode=dev
  colors,
  exporting: {
    buttons: {
      contextButton: {
        symbol: 'download',
        text: 'Export',

        menuItems: [
          // 'viewFullscreen',
          // 'printChart',
          // 'separator',
          'downloadPNG',
          'downloadPDF',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ],
      },
    },
  },
  credits: { enabled: false },
  accessibility: {
    enabled: false,
  },

  chart: {
    // styledMode: true,
    style: {
      fontFamily: 'Roboto, sans-serif',
    },
    borderRadius: 4,
    backgroundColor: 'var(--v-surface3)',
  },
  xAxis: {
    crosshair: {
      color: lineColor,
    },
    labels: {
      style: {
        color: textColor,
      },
    },
    gridLineColor: lineColor,
    lineColor,
  },
  yAxis: {
    gridLineColor: lineColor,
    lineColor,
    labels: {
      style: {
        color: textColor,
      },
    },
    title: {
      text: null,
      style: {
        color: textColor,
      },
    },
  },

  title: undefined,
  tooltip: {
    shared: true,
    valueSuffix: undefined,
    borderRadius: 8,
    borderWidth: 0,
    padding: 4,
    backgroundColor: 'var(--v-inverse-surface)',
    style: {
      color: 'var(--v-on-inverse-surface)',
    },
    useHTML: true,
    headerFormat: getTooltipHeader(),
    pointFormat: getTooltipPointFormat(),
    footerFormat: getTooltipFooter(),
    /**
     * Highcharts uses different datetime notation
     * @see https://api.highcharts.com/class-reference/Highcharts.Time#dateFormat
     */
    xDateFormat: '%d %b %Y',
  },
  legend: {
    align: 'left',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    itemMarginBottom: 10,
    itemStyle: {
      color: textColor,
    },
  },
  plotOptions: {
    line: {
      threshold: 0,
      lineWidth: 2,
    },
    spline: {
      lineWidth: 2,
    },
    series: {
      marker: {
        enabled: false,
        symbol: 'circle',
      },
    },
  },
});

NoDataModule(Highcharts);
BoostModule(Highcharts);
StockModule(Highcharts);

export default Highcharts;
