import { RouteLocationRaw, RouteRecordRaw, Router } from 'vue-router';
import { ConsoleApp } from '@console/types';
import appsRoutes from './apps';
import { getRouter } from './router/getRouter';
import { ConsoleRouteConfig, ConsoleRouteDefinition } from './types';

// Verify app exists
function checkApp(app: string) {
  if (app in appsRoutes === false) {
    throw new Error(`Unknown app ${app}`);
  }
}

// Some routes are just redirection, ignore them
const isRedirectRoute = (route: ConsoleRouteDefinition) => route.path && route.redirect && !route.name;

/**
 * Recursively checks if the given name matches any route within the nested child routes.
 * @param {string} name - The name to search for.
 * @param {RouteRecordRaw[]} children - The nested child routes to check.
 * @returns {boolean} - True if the name matches any route, false otherwise.
 */
const isNestedChildRoute = (name: string, children: RouteRecordRaw[]): boolean => {
  return children.some(child => {
    if (child.name === name) {
      return true;
    }
    if (child.children && isNestedChildRoute(name, child.children)) {
      return true;
    }
    return false;
  });
};

/**
 * Bind pre-defined route structure to application's components.
 */
export function bindAppRoutes(app: ConsoleApp, partialRoutes: ConsoleRouteConfig[]) {
  checkApp(app);

  const mapRoutes = (currentRoutes: ConsoleRouteDefinition[], userRoutes: ConsoleRouteConfig[]) => {
    // return [] as RouteRecordRaw[];
    return currentRoutes.map(route => {
      if (isRedirectRoute(route)) {
        return route as RouteRecordRaw;
      }

      const item = userRoutes.find(pr => pr.name === route.name);

      if (!item) {
        throw new Error(
          `App routes must define all routes from @console/router/apps/${app}. Route ${route.name.toString()} does not exists!`
        );
      }

      const mergedRoute = {
        ...route,
        ...item,
      } as RouteRecordRaw;

      if (route.children) {
        const nestedUserRoutes = userRoutes.filter(pr => isNestedChildRoute(pr.name.toString(), route.children));
        mergedRoute.children = mapRoutes(route.children, nestedUserRoutes);
      }

      return mergedRoute;
    });
  };

  return mapRoutes(appsRoutes[app], partialRoutes);
}

const routers: Partial<Record<ConsoleApp, Router>> = {};

/**
 * Resolve cross-app route.
 */
export function resolveAppRoute(app: ConsoleApp, params: RouteLocationRaw) {
  checkApp(app);

  if (!routers[app]) {
    routers[app] = getRouter(appsRoutes[app] as RouteRecordRaw[], app);
  }

  return routers[app].resolve(params);
}

/**
 * Shortcut to Resolve cross-app route and return the location object
 */
export function getAppRoute(app: ConsoleApp, params: RouteLocationRaw) {
  return resolveAppRoute(app, params);
}

/**
 * Shortcut to Resolve cross-app route and return the resolved route as string
 */
export function getAppRouteHref(app: ConsoleApp, params: RouteLocationRaw) {
  const href = resolveAppRoute(app, params).href;
  return href;
}
