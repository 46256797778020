export const DOCS_URI_NEW = 'https://naspersclassifieds.atlassian.net/wiki/x';

function getDocsURI(slug: string) {
  return `${DOCS_URI_NEW}/${slug}`;
}

// GENERAL
export const MAINPAGE_DOCS_URI = getDocsURI('xgHaaA0');
export const ABOUT_US_URI = getDocsURI('_wMnYQ0');
export const MY_PROFILE_DOCS_URI = getDocsURI('voCZXA0');
export const TERMS_AND_CONDITIONS_DOCS_URI = getDocsURI('wwBUbA0');
export const GLOBAL_PERMISSION_DOCS_URI = getDocsURI('44GYXA0');
export const DATA_CERTIFICATION_DOCS_URI = getDocsURI('LACFYg0');
export const ROLES_AND_PERMISSIONS_DOCS_URI = getDocsURI('fIbGZQ0');

// DATA PROJECTS
export const DATA_PROJECTS_DOCS_URI = getDocsURI('M4AsWg0');
export const DATA_PROJECTS_PERMISSIONS_DOCS_URI = getDocsURI('M4AsWg0#Roles-and-permissions');

// EXPERIMENTS
export const EXPERIMENT_TYPES_DOCS_URI = getDocsURI('IILIZQ0');
export const EXPERIMENT_DEPENDENCIES_DOCS_URI = getDocsURI('84AhXg0#Define-dependencies-between-experiments');
export const EXPERIMENT_METRICS_LIMIT_DOCS_URI = getDocsURI('1YC_Yw0#Limitations');
export const EXPERIMENTS_FAQ_DOCS_URI = getDocsURI('oIXIZQ0');
export const EXPERIMENTS_GOOD_PRACTICES_DOCS_URI = getDocsURI('yIHIZQ0');
export const EXPERIMENTS_EXPERIMENT_REPORT_DOCS_URI = getDocsURI('XYPIZQ0');
export const EXPERIMENTS_SETUP_DOCS_URI = getDocsURI('OYLIZQ0');
export const EXPERIMENTS_TRAININGS_DOCS_URI = getDocsURI('SoHIZQ0');
export const EXPERIMENTS_ERRORS_DOCS_URI = getDocsURI('MIPIZQ0');
export const CORE_WEB_VITALS_DOCS_URI = getDocsURI('JIG3Yw0');
export const FEATURE_ROLLOUTS_DOCS_URI = getDocsURI('DQFdXg0');
export const ADDING_REVENUE_METRICS_DOCS_URI = getDocsURI('oIXIZQ0#Why-can’t-I-add-Revenue-Metrics-to-my-experiment%3F');
export const QUALITY_SCORE_DOCS_URI = getDocsURI('8YHIZQ0');
export const SRM_DOCS_URI = getDocsURI('dIBiXg0#Sample-ratio-mismatch-(SRM)');

// LAZARUS
export const LAZARUS_DOCS_URI = getDocsURI('YYJOXg0');

// METRICS
export const REVENUE_METRICS_DOCS_URI = getDocsURI('LYHrdg0');

// PLAYGROUND
export const PLAYGROUND_FAQ = getDocsURI('oIXIZQ0#The-Playground');
export const PLAYGROUND_INFO_URI = getDocsURI('9gCdXg0');

// RESERVOIRS
export const RESERVOIRS_DOCS_URI = getDocsURI('V4GwXA0');
export const DATA_CONSUMPTION_DOCS_URI = getDocsURI('fIKXXA0');

// SOURCES
export const DATA_SOURCES_TERMS_AND_CONDITIONS_DOCS_URI = getDocsURI('eIGCYg0');
export const DATA_SOURCES_DOCS_URI = getDocsURI('gwV6Yg0');
export const DATA_SOURCES_ABOUT_DOCS_URI = getDocsURI('T4lsYg0');
export const PII_CLASSIFICATION_URI = getDocsURI('igGAYg0');
export const HYDRA_DOCS_URI = getDocsURI('1wBTXg0');
export const GDPR_FIELDS = getDocsURI('HALogA0');
export const DATA_SOURCES_USER_BEHAVIOUR_URI = getDocsURI('dYlsYg0');

// SURVEYS
export const SURVEY_HOW_TO_CREATE_DOCS_URI = getDocsURI('rYCYXg0');
export const SURVEY_RESTRICTIONS_DOCS_URI = getDocsURI('6wCcXg0');
export const SURVEY_TRACKING_DOCS_URI = getDocsURI('ioCgXg0');
export const SURVEY_RAW_DATA_ACCESS_DOCS_URI = getDocsURI('7wGjXg0');

// GENAI
export const GENAI_GETTING_STARTED = getDocsURI('GgSPhQ0');
export const GENAI_ENGINEERING_DOCS = getDocsURI('nIH2kw0');
export const GENAI_SPENDING_GUIDELINES = getDocsURI('RAA6jQ0');
export const GENAI_ROADMAP = getDocsURI('y4N3lQ0');
export const GENAI_AI_AT_OLX = 'https://sites.google.com/olx.com/artificial-intelligence/home';
