import { Ref, computed, ref } from 'vue';
import { useProducts } from '@console/composables/useProducts';
import { getAppRoute, getAppRouteHref, router } from '@console/router';
import { CONSOLE_PRODUCTS, SearchRecordType, SearchResult, SearchResultCount, SearchResultCountNew } from '@console/types';
import { DataTableMeta } from '@console/ui/DataTable';

export type SearchFilters = {
  search_query?: string;
  record_type?: SearchRecordType;
  page?: string;
  limit?: string;
};

const filters = ref<SearchFilters>({});
const results = ref<SearchResult[]>([]) as Ref<SearchResult[]>;
const meta = ref<DataTableMeta>(undefined);
const resultsCount = ref<SearchResultCount>(null);
const newResultsCount = ref<SearchResultCountNew>(null);

export function useGlobalSearch() {
  const setPagination = (values: SearchFilters) => {
    filters.value.page = String(values.page || 1);
    filters.value.limit = String(values.limit || filters.value.limit || 25);
  };

  const setFilters = (values: SearchFilters) => {
    filters.value = values;
    setPagination(values);
  };

  const getRouteParams = (recordType?: SearchRecordType) => {
    if (filters.value.record_type === recordType) {
      return {
        name: 'root.global-search',
        query: filters.value,
      };
    }
    return {
      name: 'root.global-search',
      query: { ...filters.value, record_type: recordType, page: '1' },
    };
  };

  const { currentProductId } = useProducts();

  const reloadResults = async () => {
    if (currentProductId.value === CONSOLE_PRODUCTS.ALL_SERVICES) {
      await router.push(getAppRoute('-root-', getRouteParams(filters.value.record_type)));
    } else {
      window.location.href = getAppRouteHref('-root-', getRouteParams(filters.value.record_type));
    }
  };

  const resetPagination = () => {
    setPagination({});
  };

  const resetFilters = () => {
    filters.value = {};
    resetPagination();
  };

  const search = async (keyword: string) => {
    resetPagination();
    if (filters.value.search_query !== keyword) {
      filters.value.search_query = keyword;
      await reloadResults();
    }
  };

  const totalFormatted = computed(() => {
    if (meta.value?.total) {
      return meta.value.total.toLocaleString();
    }
    return '';
  });

  return {
    filters,
    setFilters,
    setPagination,
    reloadResults,
    getRouteParams,
    search,
    resetFilters,
    results,
    resultsCount,
    meta,
    totalFormatted,
    newResultsCount,
  };
}
