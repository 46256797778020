import { DataTableResponse } from '@console/ui/DataTable';

export type GroupItemOwner = {
  requirements: Record<
    string,
    {
      is_reservoir_owner_certified: boolean;
      is_source_group_owner_certified: boolean;
      knowbe4_trainings_passed: boolean;
      terms_accepted: boolean;
    }
  >;
  username: string;
};

export enum ReservoirState {
  Active = 'active',
  New = 'new',
  Pending = 'pending',
  Expired = 'expired',
  PendingAccessRemoval = 'pending_access_removal',
  WaitingAccessRemoval = 'waiting_access_removal',
  PendingObjectRemoval = 'pending_object_removal',
  WaitingObjectRemoval = 'waiting_object_removal',
  PendingReservoirRemoval = 'pending_reservoir_removal',
  WaitingReservoirRemoval = 'waiting_reservoir_removal',
  Deleted = 'deleted',
}

export enum UserAccessStatuses {
  Pending = 'pending',
  PendingRemoval = 'pending_removal',
  Active = 'active',
}

export type Reservoir = {
  id: number;
  name: string;
  active: boolean;
  product_name: string;
  purpose: string;
  s3_bucket: string;
  status: 'approved' | 'pending';
  expiration_days: number;
  expires_on: string;
  groups_acl: Array<{
    acl: {
      owners: GroupItemOwner[];
      dpos: GroupItemOwner[];
    };
    id: number;
    level: number;
    name: string;
  }>;
  organization_id: number;
  owners: string[];
  source_group_id: number;
  source_group_name: string;
  is_auto_managed?: boolean;
  state: ReservoirState;
  deleted?: boolean;
  data_project_id: number;
  customer_unit_id: number;
  users: {
    user_name: string;
    state: UserAccessStatuses;
    expires_on: string | null;
    notified_expiration_at: Array<Date>;
    // organizations: Array<Partial<Organization>>;
  }[];
  authinfo: {
    can_manage: boolean;
    can_restore: boolean;
    can_request_refresh: boolean;
  };
  has_expiring_users?: boolean;
  notified_expiration_at: string[];
  unhashed_pii_count: number;
};

export type ReservoirFieldSubscriptionPayloadItem = {
  id: number;
  anonymized: boolean;
  included: boolean;
  motivation?: string;
};

export type ReservoirSubscriptionPayloadItem = {
  source_id: number;
  json: boolean;
  parquet: boolean;
  fields: ReservoirFieldSubscriptionPayloadItem[];
};

export type ReservoirUser = {
  accepted_tos: boolean;
  organizations: Record<string, any>[];
  expires_on: string;
  knowbe4_trainings_passed: boolean;
  user_name: string;
  is_external: boolean;
  state: string;
  notified_expiration_at: string[];
};

export type ReservoirSystem = {
  account_id: number;
  role_name: string;
  arn: string;
  account_display_name: string;
};

// TODO: fix subscription details page, FE should fetch each entity separately
export type SubscriptionDetailsResponseItem = {
  active: boolean;
  deleted: boolean;
  approval_status: string;
  type: string;
  anonymized: boolean;
  status: string;
  included: boolean;
  id: number;
  path: string;
  description: string;
  classification: ReservoirClassificationType;
  motivation: null;
};

export type ReservoirAvailableSource = {
  data_project_id: number;
  brand_entities: any[];
  source_class_name: string;
  source_description: string;
  source_group_id: number;
  source_group_name: string;
  source_id: number;
  source_name: string;
  source_prefix: string;
  source_status: string;
  source_tags: string[];
  source_type: string;
};

export type SubscriptionDetailsResponseSource = Omit<ReservoirAvailableSource, 'source_status'> & {
  groups_acl: any;
  owners: string[];
  dpos: string[];
};

export type ReservoirSubscription = ReservoirAvailableSource & {
  can_refresh?: boolean;
  source_group_dpos: string[];
  status: string;
  groups_acl: any;
  activity_data: any;
  is_invalid?: boolean;
  is_alive: boolean;
  invalid_reasons: string[];
  json: boolean;
  parquet: boolean;
  data_project_id: number;
  nr_pending: number;
  nr_personal: number;
  nr_pending_personal: number;
  nr_anonymized: number;
  nr_fields: number;
  nr_pending_anonymized: number;
  rejection_info: string;
};

export type SubscriptionDetailsResponseSubscriptionInfo = {
  is_invalid: boolean;
  counters: {
    active: number;
    total: number;
    personal: number;
    classified: number;
    anonymize: number;
  };
  json: boolean;
  retention: string;
  parquet: boolean;
};

export enum SubscriptionStatus {
  Cancelled = 'cancelled',
  Declined = 'rejected',
  Approved = 'approved',
  PendingOwner = 'pending_owner',
  PendingDPO = 'pending_dpo',
  PendingAdmin = 'pending_admin',
  Invalid = 'invalid',
}

export type SubscriptionRequestDetails = {
  updated_at: string;
  jira_ticket: string;
  created_at: string;
  status: SubscriptionStatus;
  parquet: boolean;
  rejection_message: string;
  user: string;
  owner_approval_message: string;
  nr_anonymized: number;
  dpo_approval_message: string;
  nr_personal: number;
  nr_pending: number;
  json: boolean;
};

export type SubscriptionDetailsResponse = DataTableResponse<SubscriptionDetailsResponseItem> & {
  source: SubscriptionDetailsResponseSource;
  reservoir: Reservoir;
  pending_request: SubscriptionRequestDetails;
  is_invalid: boolean;
  subscription: SubscriptionDetailsResponseSubscriptionInfo;
};

export type RefreshSizeEstimation = {
  number_of_files: number;
  size_in_bytes: number;
  number_of_days_selected: number;
  source_id: number;
  last_fulldump: string | null;
};

export enum SourceSubscriptionStatusSimplified {
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
  Pending = 'pending',
}

export enum SubscriptionHistoryActionType {
  AutoApproval = 'auto_approval',
  Creation = 'creation',
  DpoAprroval = 'dpo_approval',
  OwnerApproval = 'owner_approval',
  OwnerApprovalNoPersonal = 'owner_approval_no_personal',
  OwnerApprovalRequest = 'owner_approval_request',
  Rejection = 'rejection',
  OwnerRejection = 'owner_rejection',
  DpoRejection = 'dpo_rejection',
  Deletion = 'auditor_approval',
  RequestCancellation = 'request_cancellation',
  DataRefreshRequestRejection = 'data_refresh_request_rejection',
  DataRefreshRequestApproval = 'data_refresh_request_approval',
  DataRefreshRequestCancellation = 'data_refresh_request_cancellation',
  DataRefreshRequestFinish = 'data_refresh_request_finish',
  DataRefreshRequestCreation = 'data_refresh_request_creation',
  DataRefreshRequestAmendment = 'data_refresh_request_amendment',
  DataRefreshRequestPendingAdmin = 'data_refresh_request_pending_admin',
}

export enum AccessHistoryActionType {
  Created = 'reservoir_access_request_created',
  DPOAccepted = 'reservoir_access_request_accepted',
  DPORejected = 'reservoir_access_request_rejected',
  OwnerAccepted = 'reservoir_access_request_owner_accepted',
  OwnerRejected = 'reservoir_access_request_owner_rejected',
  Cancelled = 'reservoir_access_request_cancelled',
  Added = 'reservoir_access_request_add_user',
  Removed = 'reservoir_access_request_remove_user',
  RemovalRequested = 'access_request_removal_requested',
  Renewal = 'access_request_user_renewal',
  Revoked = 'reservoir_access_revoked',
}

export enum ReservoirChangeHistoryActionType {
  ReservoirChangeCreated = 'reservoir_change_creation',
  ReservoirChangeUpdated = 'reservoir_change_update',
  ReservoirChangeApproved = 'reservoir_change_approval',
  ReservoirChangeRejected = 'reservoir_change_rejection',
  ReservoirChangeCancelled = 'reservoir_change_cancellation',
  ReservoirExpiration = 'reservoir_expiration',
  ReservoirRenewal = 'reservoir_renewal',
  CustomRequestCreated = 'request_creation',
  CustomRequestApproved = 'request_approval',
  CustomRequestRejected = 'request_rejection',
  CustomRequestCancelled = 'request_cancellation',
}

export enum ReservoirHistoryType {
  ReservoirChange = 'reservoir_attribute_change',
  CustomRequest = 'reservoir_custom_request',
  DataRefreshRequest = 'reservoir_refresh_requests',
  SubscriptionRequest = 'subscription_requests',
}

export enum ReservoirClassificationType {
  Personal = 'personal',
  PersonalOperational = 'personal_operational',
  NonPersonal = 'non_personal',
}

export enum ReservoirExpirationAction {
  Renew = 'renew',
  Expire = 'expire',
  Restore = 'restore',
}

export type MyReservoir = {
  reservoir_id: number;
  organization_id: number;
  organization_name: string;
  role: string;
  reservoir_name: string;
};

export type DataRefreshSubscription = {
  source_id: number;
  source_name: string;
  json: boolean;
  parquet: boolean;
  refresh_json?: boolean;
  refresh_parquet?: boolean;
};

export type DataRefreshRequest = {
  subscriptions: Array<DataRefreshSubscription>;
  from_date: string;
  to_date: string;
  reason: string;
};

export enum DataRefreshFormatRefresh {
  JSON = 'refresh_json',
  Parquet = 'refresh_parquet',
}

export enum CustomRequestTypes {
  SystemAccess = 'system_access',
  Other = 'other',
}

export type ReservoirCustomRequest = {
  subject: string;
  description: string;
  request_type: CustomRequestTypes;
  team: string;
  gitlab_repo?: string;
};
