import type { OpslevelTeam, UserRole } from './data-projects';

export enum UserCertifications {
  ReservoirOwner = 'Reservoir owner',
  SourceOwner = 'Source owner',
}

export type UserCertificate = {
  key: UserCertifications;
  expires_at: string;
  colorClass?: string;
  tooltipText?: string;
};

/**
 * User info, returned by coffer `/user/info`
 */
export type UserInfo = {
  authinfo: {
    can_impersonate: boolean;
    can_create_reservoir: boolean;
  };
  email: string;
  id: string;
  lastname: string;
  name: string;
  is_impersonated: boolean;
  requirements: {
    terms_accepted_at: string | null;
    knowbe4_trainings_passed: boolean;
    certificates: UserCertificate[];
    privacy_training_passed_at: string | null;
  };
  user_affiliations: {
    customer_units: number[];
    data_projects: number[];
    opslevel_teams: OpslevelTeam[];
  };
};

// Trainings
export enum UserTrainingCampaignStatus {
  Completed = 'Completed',
  InProgress = 'In Progress',
  Closed = 'Closed',
}

export enum UserTrainingModuleStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Passed = 'Passed',
  PastDue = 'Past Due',
}
export type UserTraining = {
  campaign_id: number;
  campaign_status: UserTrainingCampaignStatus;
  completion_date: Date;
  completion_deadline: Date;
  email: string;
  enrollment_date: Date;
  module_name: string;
  module_status: UserTrainingModuleStatus;
};

type UnitData = { id?: number; key?: string; name: string; description: string };

export type UserRoleData = {
  policy: {
    key: UserRole;
    name: string;
  };
  unit_type: string;
  business_unit?: UnitData;
  customer_unit?: UnitData;
  data_project?: UnitData;
};
